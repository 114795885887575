<template>
  <div>
    <div class="main_content align-items-center">
      <div class="row justify-content-between">
        <div class="col-sm-8">
          <div class="d-lg-flex justify-content-between top_heading">
            <div class="heading">
              <h2 style="color:#39353D;">Help Center</h2>
              <h3 style="color:#39353D;">Employee Dashboard</h3>
            </div>
            <div class="search_bar d-none">
              <form action="">
                <input type="text" placeholder="How Can We Help You?" class="form-control" />
                <a href="#" class="search_icon"><img src="../../assets/images/dashboard/Search_gray.svg" alt="img" /></a>
              </form>

            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 text-sm-end mt-md-0 mt-2">
        <!-- nothing -->
      </div>
      <div class="first_section dashboard_show hlp_center padd_help_center">
        <div class="row">
          <div class="col-lg-8">
            <div class="client_accordian">
              <div v-if="helpCenterData.length == 0"
                class="accordion d-flex justify-content-center align-items-center cntr_data" id="accordionExample">
                <div class="card_heading mt_40 mb_30 ">
                  <h4 class="heading2 no_data size_28">No Data Found</h4>
                </div>
              </div>
              <div class="accordion" id="accordionExample" v-else>
                <div v-for="(data, indx) in helpCenterData" :key="indx">
                  <div class="card_heading mt_40 mb_30">
                    <h4 class="heading2">{{ indx }}</h4>
                  </div>
                  <div v-for="(data1, indx1) in data" :key="indx1" class="card add_client" :id="'get_' + data1.id">
                    <div class="accordion-item">
                      <h2 class="accordion-header" :id="'headingT' + data1.id" @click="scroll_it(data1.id)">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          :data-bs-target="'#collapseT' + data1.id" aria-expanded="false"
                          :aria-controls="'collapseT' + data1.id">
                          {{ data1.question }}
                        </button>
                      </h2>
                      <div :id="'collapseT' + data1.id" class="accordion-collapse collapse"
                        :aria-labelledby="'headingT' + data1.id" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="claim_box d-flex">
                            <div v-if="data1.icon" class="claim_text_image_box">
                              <img :src="base_url + '/public/client/images/help_center/' + data1.icon" alt="img"
                                style="width:40px !important">
                            </div>
                            <div class="claim_text_box ml_20">
                              <div v-html="data1.answer"></div>
                              <div v-if="data1.vimeoVideos" class="hc_vdo_buttons_list row">
                                <div v-for="(vimeoVideo, indx2) in data1.vimeoVideos" :key="indx2" class="hc_vdo_buttons_listin col-xl-6">
                                  <a href="javascript:void(0)" class="btn btn_outline_hc" data-bs-toggle="modal"
                                  data-bs-target="#exampleModal1" @click="openVimeo(vimeoVideo.linkUrl)">
                                  <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                              viewBox="0 0 31 31">
                              <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                              <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                 stroke-linejoin="round" stroke-miterlimit="10">
                                 <path
                                    d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                    stroke="none"></path>
                                 <path
                                    d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                    stroke="none" fill="#fff"></path>
                              </g>
                           </svg> {{ vimeoVideo.linkTitle }}
                                </a>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card site_footer mb_50 border_radius_20">
              <div class="card_heading">
                <h4 class="heading2">Contact Us</h4>
              </div>
              <iframe loading="lazy" src="https://survey.alchemer.com/s3/7270906/PBergo-com-Contact-Form" height="510px"
                width="100%"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal start Here -->
    <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade vimeovdomodal" id="exampleModal1" tabindex="-1"
      aria-labelledby="exampleModalLabel" @click.self="close" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <Spinner ref="Spinner"></Spinner>
            <div class="text-end">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal">
                <img :src="require('@/assets/images/close_modal.png')" />
              </a>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe id="vimeoIframe" :src="this.videoUrl" frameborder="0" style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              " mozallowfullscreen webkitallowfullscreen allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
  </div>
</template>
<script>
import commonFunction from '../../mixin/commonFunction';
import Spinner from "@/components/Spinner.vue"
import $ from "jquery";
export default {
  name: "help_center",
  mixins: [commonFunction],
  el: '#example-1',
  computed: {
    btnText: function () {
      if (this.show) {
        return ''
      }
      return ''
    }
  },
  components: {
    Spinner
  },
  data() {
    return {
      show: false,
      helpCenterData: [],
      videoUrl: "",
    };
  },
  destroyed() {
    this.$parent.employeeDashboard = false
    // this.$router.go()
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.$parent.employeeDashboard = true;
    this.getHelpCenter();
    this.base_url = process.env.VUE_APP_API_URL;
  },
  methods: {
    openVimeo(vimeoUrl) {
      this.$refs.Spinner.modalLoader = true;
      if (vimeoUrl) {
        this.videoUrl = vimeoUrl;
        setTimeout(() => {
          this.$refs.Spinner.modalLoader = false;
        }, 2000);

      }
    },
    hideModal() {
      document.getElementById("vimeoIframe").src += "";
      this.videoUrl = "";
    },
    getHelpCenter() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/help-center-data",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: '',
      };
      _this.axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.helpCenterData = decodedJson.records;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
        });
    },
    reloadPage() {
      window.location.reload();
    },
    scroll_it(id) {
      setTimeout(function () {
        const mediaQuery = window.matchMedia('(max-width: 767px)')
        if (mediaQuery.matches) {
          $('html,body').animate({
            scrollTop: $("#get_" + id).offset().top
          }, 'slow');
        }
      }, 500);
    },
  },
}
</script>